/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onInitialClientRender = () => {
  if (
    "onGatsbyInitialClientRender" in window &&
    typeof window.onGatsbyInitialClientRender === "function"
  ) {
    window.onGatsbyInitialClientRender();
  }
  $(document).ready(function () {
    $.ajax({
      type: "GET",
      url: "https://neno-api-v1.herokuapp.com/coins.json",
      dataType: "json",
      success: function (response) {
        sessionStorage.setItem("250TC_comprar", response[0].venda);
        sessionStorage.setItem("1500TC_comprar", response[1].venda);
        sessionStorage.setItem("3000TC_comprar", response[2].venda);
        sessionStorage.setItem("250TC_vender", response[0].compra);
      },
    });
  });
};

exports.onRouteUpdate = () => {
  if (
    "onGatsbyRouteUpdate" in window &&
    typeof window.onGatsbyRouteUpdate === "function"
  ) {
    window.onGatsbyRouteUpdate();
  }
};
